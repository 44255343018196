.connect_box{
    margin: 0 4%;
    h2{
        text-align: center;
        font-family: 'Goudy Bookletter 1911';
        font-style: normal;
        font-weight: bold;
        font-size: 1.6rem;
        margin: 3em 0 1.2em 0;
    }

    .form_box{
        display: flex;
        flex-direction: column;
        gap: 1em;
        margin: 0em 0;
        background-color: rgb(231, 231, 231);
        border-radius: 20px;
        padding: 1em;

        h3{
            text-align: start; 
            font-size: 1.7rem;
            border-bottom: 4px black solid;
            width: fit-content;
            margin: 1em 0 1em 0;
        }

        .fieldset{
            display: flex;
            flex-direction: column;
            gap: .5em;

            label{
                font-weight: 500;
            }

            input, textarea{
                outline: none;
                border: 1px solid grey;
                padding: .8em .5em;
                border-radius: 10px;
                font-size: 1.1rem;
            }

            span{
                color: red;
                &:before{
                    content: " ";
                }
            }

           .input_error{
            display: flex;
            flex-direction: column;
           }
        }

        button{
            max-width: 10em;
            margin: .3em auto;
            font-size: 1.2rem;
            color: white;
            padding: 1em 3em;
            font-weight: 600;
            border: none;
            font-weight: bold;
            border-radius: 20px;
            background-color: rgb(0, 177, 213);
            cursor: pointer;
            transition: .4s;

            &:hover{
                scale: 1.1;
            }

            &:active{
                scale: 1;
                transition: .2s;
            }
        }
    }
}

@media only screen and (min-width: 880px){
    .connect_box{
        background-color: rgb(231, 231, 231);
        // width: 100%;
        margin: 0 !important;
        padding: 2em 0;
        display: grid;
        place-items: center;
        border-radius: 20px;

        .form_box{
            max-width: 1000px;
            padding: 2em;
            border-radius: 30px;
            gap: 2em;
        
            .fieldset{
                flex-direction: row;
                justify-content: space-between;

                label{
                    font-size: 1.3rem;
                }

                input, textarea{
                  min-width: 30em;
                  max-width: 40em;
                }
            }
        }
    }
}
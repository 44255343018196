*{
    box-sizing: border-box;
    margin: 0;
}

.form-box{
    background-color: rgba(228, 228, 228, 0.363);
    border-radius: 15px;
    padding: 3em;
    margin: 2em auto;
    margin-top: 1em;
    max-width:46em;

    .form-items {
        display: grid;
        grid-template-columns: 50% auto;
        margin: 1em 0;
        min-height: 1.8em;

         h6 {
            font-size: 1rem;
            color: red;
            text-align: center;
        }

        .error{
            text-align: center;
        }

        p{
            font-size: 1.1rem;
            font-weight: 500;
        }

        input{
            min-width: 100%;
            border-radius: 5px;
            border: 1px solid rgb(202, 202, 202);
            padding: .5em;
            outline: none;
            height: 2.5em;
        }

        textarea{
            width: 100%;
            min-height: 3.2em;
            border-radius: 5px;
            border: 1px solid rgb(202, 202, 202);
            padding: .5em;
            outline: none;
            padding: 5px; outline: none;

        }

        .cv-input{
            position: relative;

            input[id='file-input']{
                min-width: 100%;
                height: 10em;
                position: absolute;
                opacity: 0;
                cursor: pointer;
            }

            label[for="file-input"]{
                background-color: white;
                min-width: 100%;
                min-height: 10em;
                border-radius: 5px;
                border: 1px solid rgb(202, 202, 202);
                display: inline-block;
                cursor: pointer;

                svg{
                    width: 6em;
                    height: auto;
                    margin-left: 50%;
                    transform: translate(-50%);
                }

                p:nth-child(3){
                    font-size: 0.9rem;
                    margin-left: 50%;
                    transform: translate(-50%);
                    color: rgb(82, 82, 82);
                    width: max-content;
                }
                
                p:nth-child(2){
                    margin-left: 50%;
                    transform: translate(-50%);
                    width:max-content;
                }
            }

            p[id='file-name']{
                text-align: center;
                margin: .5em 0 0 0;
            }
        }
    }

    button {
        color:white;
        padding: 0.6em 1em;
        margin-left: 50%;
        transform: translate(-50%);
        margin-top: 1em;
        width: 6em;
        text-align: center;
        border-radius: 5px;
        border: none;
        background-color: #0076BE;
        cursor: pointer;
        transition: 0.1s all;
        z-index: -1;

        &:active{
            scale: .98;
        }
    }
}


@media only screen and (max-width: 500px){
    .form-box{   
        .form-items{
            display: flex;
            flex-direction: column;
            gap: 1em;
            margin-top: 1.5em;

            input{
                width: 100%;
                height: 3.5em;
            }

            textarea{
                width: 100%;
                height: 6em;
            }

            h6{
                font-size: 1.2rem;
            }

            label[for="file-input"]{
                width: 100%;
                padding: 2em;
            }
        
            input[id='file-input']{
                width: 100%;
            }
         
            
            label[for="file-input"] svg{
                margin-left: 50%;
            }
                        
        }
    }
}
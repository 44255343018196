.outerContainer {
  margin-bottom: 4em;
  .serviceContent {
    text-align: center;
    margin: auto;
    margin-bottom: 2em;
    font-size: 2em;

    @media screen and (min-width: 1024px) {
      width: 20%;
    }
  }
  .mainContainer {
    display: flex;
    flex-direction: column;
    row-gap: 2em;
    column-gap: 2em;
    .container {
      box-shadow: 2px 2px 5px rgba(0, 0, 0);
      border-radius: 0.5em;
      .scaleImage {
        display: block;
        margin: auto;
        width: 100%;
        height: 15em;
        border-top-left-radius: 0.5em;
        border-top-right-radius: 0.5em;
        @media screen and (min-width: 1024px) {
          height: 22em;
        }
      }

      .designTitle {
        margin: auto;
        text-align: center;
        margin-top: 1em;
        padding: 1em;
        text-align: center;
        font-size: 1.05em;
        font-weight: 700;
      }
      @media screen and (min-width: 1024px) {
        width: 30%;
      }
    }
    @media screen and (min-width: 1024px) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      margin-top: 2em;
      justify-content: center;
    }
  }
}

@import url("https://fonts.googleapis.com/css2?family=Goudy+Bookletter+1911&display=swap");

.landing_page {
  margin: 0 4%;
  .title {
    // display: grid;
    // grid-template-columns: 1fr 1fr;

    h1 {
      // max-width: 7em;
      width: 100%;
      font-weight: 400;
      font-size: 2.2rem;
      text-align: center;
    }

    img {
      border-radius: 80px;
      max-width: 14em;
      height: 10em;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    }
  }

  h2 {
    font-style: normal;
    font-weight: 550;
    font-size: 1.5rem;
    line-height: 19px;
    margin: 1em 0;
    text-align: center;
  }

  h3 {
    font-style: normal;
    font-weight: 780;
    font-size: 1.2rem;
    text-align: center;
    margin: 1em 0;
  }

  img {
    width: 100%;
  }

  .description {
    text-align: justify;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5em;
    max-width: 100%;
    margin: 1em 0;
    font-family: "Goudy Bookletter 1911";
  }

  .about-us{
    font-size: 1.8rem;
    font-weight: 900;
    text-align: center;
    margin: 1.8em 0 .8em 0;
  }
}


@media screen and (min-width:1100px) {
  .landing_page {
    margin: 0 4%;
    .title {
  
      h1 {
        width: 100%;
        font-weight: 400;
        font-size: 4rem;
        text-align: center;
      }
  
      img {
        border-radius: 80px;
        max-width: 14em;
        height: 10em;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
      }
    }
  
    h2 {
      font-style: normal;
      font-weight: 550;
      font-size: 2rem;
      line-height: 19px;
      margin: 1em 0;
      text-align: center;
    }
  }
}
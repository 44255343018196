.outerContainer {
  margin-bottom: 4em;
  .serviceContent {
    text-align: center;
    margin: auto;
    font-size: 2em;

    @media screen and (min-width: 1024px) {
      width: 20%;
    }
  }
  .mainContainer {
    display: flex;
    flex-direction: column;
    row-gap: 2em;
    margin-top: 1.5em;
    .container {
      box-shadow: 2px 2px 5px rgba(0, 0, 0);
      padding: 1em;
      border-radius: 0.5em;
      .scaleImage {
        display: block;
        width: 100%;
        margin: auto;
        // @media screen and (min-width: 1024px) {
        //   width: 100%;
        // }
      }

      .designTitle {
        margin: auto;
        text-align: center;
        margin-top: 1em;
        font-size: 1em;
        font-weight: 700;
      }

      .designDescription {
        text-align: justify;
        font-family: "Goudy Bookletter 1911";
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.5em;
        max-width: 100%;
        margin-top: 1.5em;
      }
      @media screen and (min-width: 1024px) {
        width: 30%;
      }
    }
    @media screen and (min-width: 1024px) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      column-gap: 2em;
      row-gap: 4em;
      margin-top: 2em;
    }
  }
}

.team{
    margin: 5em 0;

    h1{
        text-align: center;
        margin: 1em 0;
        font-size: 2rem;
    }

    .members{
        display: flex;
        flex-wrap: wrap;
        gap: 4em;
        justify-content: space-evenly;

        .member{
            box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
            min-width: 15.6em;
            border-radius: 10px;
            padding: 1em;
            display: grid;
            grid-auto-flow: row;
            height: 18.7em;

            .member-head{
                position: relative;
                background-color: #2f2f2f;
                min-height: 2em;
                border-radius: 10px 10px 0 0;

                img{
                    position: absolute;
                    width: 10em;
                    min-height: 10em;
                    border-radius: 50%;
                    margin-left: 50%;
                    transform: translate(-50%);
                    padding: .3em;
                    background-color: #a3a3a3b9;
                    margin-top: 1em;
                }
            }

            .member-name{
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
            }
        }
    }
}
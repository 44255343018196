* {
  text-decoration: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

$background_color: #3f3f3f;
$hover_color: #6a6a6a;

@keyframes side_from_right {
  from {
    right: -100%;
  }

  to {
    right: 0;
  }
}
.close_nav_active {
  background-color: rgba(145, 145, 145, 0.181);
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 990;
}
.close_nav {
  display: none;
}

nav {
  margin: 0 4%;
  padding: 1em 0;
  display: grid;
  grid-template-columns: 1fr 0.1fr;
  overflow-x: hidden;

  .sidebar {
    display: none;
  }

  li {
    position: relative;
  }

  .sidebar_active {
    position: fixed;
    right: 0;
    top: 0;
    width: 50vw;
    background-color: rgb(125, 122, 122);;
    padding: 1em 0;
    transition: 0.4s;
    padding: 4.5em 0 1em 0;
    animation: side_from_right 0.4s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    gap: 1em;
    z-index: 998;
    // align-items: center;
    margin-right: 1em;

    a {
      //Link
      color: white;
      font-size: 1.2rem;
      padding: 0.2em 0.8em;
      transition: 0.4s;
      display: flex;
      justify-content: flex-end;

      &:hover {
        background-color: $hover_color;
        border-radius: 10px;
      }
    }
  }

  .dropdown {
    display: none;
  }

  .projects_dropdown {
    width: 2em;
    object-fit: contain;
    background-color: transparent;
    // border: none;
    padding: 0;
    margin: 0;

    svg {
      position: relative;
      top: 0em;
      padding: 0;
      margin: 0;
      font-size: 2em;
    }
  }

  .menu {
    margin-left: auto;
    max-width: 3em;
    z-index: 999;
    cursor: pointer;
    height: 3em;
    position: fixed;
    right: 4%;
  }
}

@media only screen and (min-width: 1100px) {
  * {
    font-size: 15px;
  }

  nav {
    grid-template-columns: 0.1fr 1fr;

    .close_nav_active {
      display: none;
    }

    .sidebar {
      margin: 4em 0 0 0;
      display: flex;
      justify-content: flex-end;
      gap: 1em;
      width: max-content;
      text-transform: uppercase;
      letter-spacing: 0.12em;

      a {
        color: black;
        font-size: 1.2rem;
        font-weight: bold;
        padding: 0.5em 1em;
        transition: 0.3s;
        border-radius: 10px;
        text-align: center;
        transition: color 0.2s ease-in-out,background-color 1s ease-in-out;

        &:hover {
          background-color: rgb(38, 77, 115);
          color: white;
        }
      }
    }

    .sidebar_active {
      margin: 4em 0 0 0;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      align-items: center;
      position: static;
      background-color: transparent;
      padding: 0;
      margin: 0;
      width: 100%;

      a {
        color: black;
        font-size: 1.2rem;
        font-weight: bold;
        padding: 0.2em 0.5em;
        transition: 0.3s;
        border-radius: 10px;
        text-align: center;

        &:hover {
          background-color: rgb(185, 255, 255);
        }
      }
    }

    svg {
      display: none;
    }
  }
}

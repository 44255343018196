.dropdown_content{
    position: relative;
    left: 1em;
    right: 1em;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-content: flex-end;
    gap: .3em;
    margin: .5em 0 0 0;

    li{
        position: relative;
        padding: 0 1em;
    }
}


@media only screen and (min-width: 1100px) {
    .dropdown_content{
        position: absolute;
        left: 1em;
        right: 1em;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-content: flex-end;
        gap: .6em;
        margin: 1.2em 0 0 0.8em;
        padding: .1em 0 0 0;
        
        li{
            position: relative;
            right: 2em;
            bottom: .5em;
            min-width: max-content;
        }
    }
}
.footer_box {
  background-color: #25634c;
  color: white;
  overflow-x: hidden;
  margin-top: 4em;

  iframe {
    margin: auto;
    width: 80%;
    display: block;
  }

  .company_info {
    padding: 1em;
    img {
      float: left;
    }

    p {
      text-align: justify;
      font-size: 1.1rem;
      line-height: 1.5em;
      margin: 1em;
    }
  }

  .company_details {
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    display: flex;
    // justify-content: space-between;
    flex-direction: column;
    row-gap: 3em;
    margin: 1em 4%;

    .about {
      ul {
        margin: 0.5em 0 0 0;
        a,
        li {
          margin: 0 0 0 1em;
          font-size: 1rem;
          margin: 0.5em 0 0 0;
          font-weight: 450;
          color: white;
        }
      }
    }

    .contact {
      display: flex;
      flex-direction: column;
      row-gap: 0.6em;
      h4 {
        margin: 0em 0 1em 0;
      }

      a {
        color: white;
      }
    }
  }

  .follow {
    margin: 0 4%;

    ul {
      display: flex;
      column-gap: 1em;
      margin-top: 1em;
    }
  }

  .copy_right {
    text-align: center;
    background-color: #355f52;
    display: grid;
    padding: 1.5em 0;
    color: white;
    font-weight: 600;
  }
}

@media only screen and (min-width: 880px) {
  .footer_box {
    .company_info {
      p {
        text-align: center;
        letter-spacing: -0.03;
        font-size: 2em;
      }
    }
    .company_details {
      width: 80%;
      margin: auto;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 4em;
      margin-bottom: 3em;

      .about {
        ul {
          a {
            color: white;
            li {
              font-size: 1.5em;
            }
            &:hover li {
              color: steelblue;
            }
          }
        }
      }

      .contact {
        text-align: end;
        display: flex;
        flex-direction: column;
        row-gap: 0.6em;
        a:hover{
          color: steelblue;
        };
        a,h4,
          p {
            font-size: 1.2em;
          }
      }
      .follow {
        align-self: center;
        p {
          text-align: center;
          font-size: 1.5em;
        }
        ul {
          display: flex;
          column-gap: 1em;
          margin-top: 1em;
        }
      }
    }
  }
}
